// Gets data from user interface and delegates the action
import '@babel/polyfill';
import { login, logout, forgotPassword, resetPassword, loadSearchResults, createNewsletter, updateReservaEmprestado, updateReservaDevolvido, createSignup, createUser, deleteUser, approveUser, updateSettings } from './login';
import { createBiblioteca, createAtividade, createReserva, updateBiblioteca, updateAtividade, deleteBiblioteca, deleteAtividade, deleteReserva, createReservationAdmin, adminDeleteReservation} from './manageRessources';
import { createCalendarEvent } from './calendarEvent';
// import { sendNewsletter } from './get-emails';

// DOM elements
const signupForm = document.querySelector('#form-criar-conta');
const approveUserBtn = document.querySelector('#btn-approve-user');
const loginForm = document.querySelector('.form--login');
const forgotPasswordForm = document.querySelector('.form-forgot-password');
const resetPasswordForm = document.querySelector('.form-reset-password');
const logoutBtn = document.querySelector('.nav__el--logout');
const userPasswordForm = document.querySelector('.form-user-password');

const bibliotecaDataForm = document.querySelector('.form-biblioteca-data');
const atividadeDataForm = document.querySelector('.form-atividade-data');
const userDataForm = document.querySelector('.form-user-data');

const createBibliotecaForm = document.querySelector('#form-create-biblioteca');
const createAtividadeForm = document.querySelector('#form-create-atividade');
const createReservaForm = document.querySelector('#form-create-reserva');
const createUserForm = document.querySelector('#form-criar-usuario');
const createReservaBtn = document.getElementById('#reservar-biblioteca');
const botao = document.getElementById('#botaosinho');

const adminCreateReservationBtn = document.querySelector('#btn-create-reservation-admin');
const deleteBibliotecaBtn = document.querySelector('#btn-delete-biblioteca');
const deleteAtividadeBtn = document.querySelector('#btn-delete-atividade');
const deleteUserBtn = document.querySelector('#btn-delete-user');
const deleteReservaBtn = document.querySelector('#btn-delete-reserva');
const deleteReservaAdminBtn = document.querySelector('#btn-delete-reserva-admin');

const reservaEmprestadoBtn = document.querySelector('#btn-confirm-emprestimo');
const reservaDevolvidoBtn = document.querySelector('#btn-confirm-devolucao');
const searchForm = document.querySelector('.searchForm');
const newsletterForm = document.querySelector('.form--newsletter');




if (newsletterForm)
    newsletterForm.addEventListener('submit', e => {
    e.preventDefault();

    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;

    createNewsletter(name, email);
});


if (searchForm)
    searchForm.addEventListener('submit', e => {
        e.preventDefault();
        const search = document.getElementById('search').value;
        loadSearchResults(search);
    });


if (loginForm)
    loginForm.addEventListener('submit', e => {
        e.preventDefault();
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        login(email, password);
});

if (logoutBtn) logoutBtn.addEventListener('click', logout);

if (forgotPasswordForm)
    forgotPasswordForm.addEventListener('submit', e => {
        e.preventDefault();
        const email = document.getElementById('email').value;
        forgotPassword(email);
});

if (resetPasswordForm)
    resetPasswordForm.addEventListener('submit', e => {
        e.preventDefault();
        const {resetToken} = e.target.dataset
        const password = document.getElementById('password').value;
        const passwordConfirm = document.getElementById('password-confirm').value;
        console.log(password,passwordConfirm,resetToken )
        console.log(e.target.dataset.resetToken)
        resetPassword({password, passwordConfirm}, resetToken);
});

if (logoutBtn) logoutBtn.addEventListener('click', logout);

if (deleteReservaBtn)
    deleteReservaBtn.addEventListener('click', e => {
        e.preventDefault();
        const { reservaId } = e.target.dataset;
        const { userId } = e.target.dataset;
        deleteReserva(reservaId, userId);
});

if (deleteReservaAdminBtn)
    deleteReservaAdminBtn.addEventListener('click', e => {
        e.preventDefault();
        const { reservaId } = e.target.dataset;
        const { userId } = e.target.dataset;
        adminDeleteReservation(reservaId, userId);
});

if (deleteUserBtn)
    deleteUserBtn.addEventListener('click', e => {
        e.preventDefault();
        const { userId } = e.target.dataset;
        deleteUser(userId);
});

if (approveUserBtn)
    approveUserBtn.addEventListener('click', e => {
        e.preventDefault();
        const { userId } = e.target.dataset;
        approveUser(userId);
});

if (reservaEmprestadoBtn)
    reservaEmprestadoBtn.addEventListener('click', e => {
        e.preventDefault();
        const { reservaId } = e.target.dataset;
        const { userId } = e.target.dataset;
        updateReservaEmprestado(reservaId, userId);
});

if (reservaDevolvidoBtn)
    reservaDevolvidoBtn.addEventListener('click', e => {
        e.preventDefault();
        const { reservaId } = e.target.dataset;
        const { userId } = e.target.dataset;
        updateReservaDevolvido(reservaId, userId);
});

if (createAtividadeForm)
    createAtividadeForm.addEventListener('submit', e => {
        e.preventDefault();
        const form = new FormData();
        form.append('name', document.getElementById('name').value);
        // form.append('subtext', document.getElementById('subtext').value);
        form.append('age', document.getElementById('age').value);
        form.append('local', document.getElementById('local').value);
        form.append('date', document.getElementById('date').value);
        form.append('time', document.getElementById('time').value);
        form.append('obs', document.getElementById('obs').value);
        form.append('summary', document.getElementById('summary').value);
        form.append('description', document.getElementById('description').value);
        form.append('formlink', document.getElementById('formlink').value);
        form.append('photo', document.getElementById('photo').files[0]);

        createAtividade(form);
});


if (createBibliotecaForm)
    createBibliotecaForm.addEventListener('submit', e => {
        e.preventDefault();
        const form = new FormData();
        form.append('name', document.getElementById('name').value);
        form.append('author', document.getElementById('author').value);
        form.append('photo', document.getElementById('photo').files[0]);

        createBiblioteca(form);
});


if (userDataForm)
    userDataForm.addEventListener('submit', e => {
    e.preventDefault();
    const form = new FormData();
    form.append('name', document.getElementById('name').value);
    form.append('email', document.getElementById('email').value);
    form.append('photo', document.getElementById('photo').files[0]);
    
    updateSettings(form, 'data');
});

if (userPasswordForm)
    userPasswordForm.addEventListener('submit', async e => {
    e.preventDefault();
    document.querySelector('.btn--save-password').textContent = 'Atualizando...';

    const passwordCurrent = document.getElementById('password-current').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;
    await updateSettings({passwordCurrent, password, passwordConfirm }, 'password');
    
    document.querySelector('.btn--save-password').textContent = 'Salvar senha';
    document.getElementById('password-current').value = '';
    document.getElementById('password').value = '';
    document.getElementById('password-confirm').value = '';
});

if (deleteBibliotecaBtn)
    deleteBibliotecaBtn.addEventListener('click', e => {
        e.preventDefault();
        const { bibliotecaId } = e.target.dataset;
        deleteBiblioteca(bibliotecaId);
});

if (deleteAtividadeBtn)
    deleteAtividadeBtn.addEventListener('click', e => {
        e.preventDefault();
        const { atividadeId } = e.target.dataset;
        deleteAtividade(atividadeId);
});

if (createReservaForm)
    createReservaForm.addEventListener('submit', e => {
    e.preventDefault();
    const { bibliotecaId } = e.target.dataset;
    const form = new FormData();
    form.append('user', document.getElementById('user').value);
    // form.append('author', document.getElementById('author').value);
    form.append('biblioteca', document.getElementById('biblioteca').value);
    
    createReserva(form, bibliotecaId);
});

if (adminCreateReservationBtn)
    adminCreateReservationBtn.addEventListener('click', e => {
    e.preventDefault();
    // console.log(e.target);
    const { userId } = e.target.dataset;
    const { bookId } = e.target.dataset;
    
    createReservationAdmin(userId, bookId);
});

if (signupForm)
    signupForm.addEventListener('submit', e => {
    e.preventDefault();

    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;

    createSignup(name, email, password, passwordConfirm);
});

if (createUserForm)
    createUserForm.addEventListener('submit', e => {
    e.preventDefault();

    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;

    createUser(name, email, password, passwordConfirm);
    console.log(name)
    console.log(email)
    console.log(password)
    console.log(passwordConfirm)

});


if(bibliotecaDataForm)
    bibliotecaDataForm.addEventListener('submit', e => {
        e.preventDefault();
        const bibliotecaId = e.target.dataset.bibliotecaId;
        const form = new FormData();
        form.append('name', document.getElementById('name').value);
        form.append('author', document.getElementById('author').value);
        form.append('photo', document.getElementById('photo').files[0]);

        updateBiblioteca(form, bibliotecaId);
    });

if (atividadeDataForm)
    atividadeDataForm.addEventListener('submit', e => {
        e.preventDefault();
        const atividadeId = e.target.dataset.atividadeId;
        const form = new FormData();
        form.append('name', document.getElementById('name').value);
        form.append('date', document.getElementById('date').value);
        form.append('age', document.getElementById('age').value);
        form.append('time', document.getElementById('time').value);
        form.append('local', document.getElementById('local').value);
        form.append('summary', document.getElementById('summary').value);
        form.append('description', document.getElementById('description').value);
        form.append('formlink', document.getElementById('formlink').value);
        form.append('photo', document.getElementById('photo').files[0]);
        
        updateAtividade(form, atividadeId);
});

