import axios from 'axios';
import { showAlert } from './alerts';
// import { } from './login;'

export const createSignup = async (name, email, password, passwordConfirm) => {
  try {
    const res = await axios ({
      method: 'POST',
      url: '/api/v1/users/signup',
      data: {
        name,
        email,
        password,
        passwordConfirm
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Conta criada com sucesso!');
      window.setTimeout(() => {
        location.assign('/');
      }, 1500);
    }


  } catch (err) {
    showAlert('error', err.response.data.message);
    
    // if(!err.response.data.message){
    //   showAlert('error', 'Desculpe, algo deu errado')

    // }
    
  }

  
};

export const createUser = async (name, email, password, passwordConfirm) => {
  try {
    const res = await axios ({
      method: 'POST',
      url: '/api/v1/users',
      data: {
        name,
        email,
        password,
        passwordConfirm
      }
    });
    

    if (res.data.status === 'success') {
      showAlert('success', 'Usuário criado com sucesso!');
      window.setTimeout(() => {
        location.assign('/');
      }, 1500);
    }


  } catch (err) {
    // showAlert('error', 'Algo deu errado');
    showAlert('error', err.response.data.message)
    if (!err.response.data.message) {
      showAlert('error', 'Algo deu errado')
    }
  }
};


export const login = async (email, password) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/login',
      data: {
        email,
        password
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Login com sucesso!');
      window.setTimeout(() => {
        location.assign('/');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const forgotPassword = async (email) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/forgotPassword',
      data: {
        email
      }
    });
    console.log(email)

    if (res.data.status === 'success') {
      showAlert('success', 'Email enviado!');
      window.setTimeout(() => {
        location.assign('/');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const resetPassword = async (data, token) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/resetPassword/${token}`,
      data,
      token
    });
    console.log(data)
    console.log(token)

    if (res.data.status === 'success') {
      showAlert('success', 'Senha alterada com sucesso!');
      window.setTimeout(() => {
        location.assign('/');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const logout = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/api/v1/users/logout'
    });
    if ((res.data.status = 'success')) location.assign('/');
  } catch (err) {
    console.log(err.response);
    showAlert('error', 'Erro ao sair! Tente novamente.');
  }
};


export const deleteUser = async userId => {
  try {
    // const slug = req.params.slug;
    const res = await axios ({
      method: 'DELETE',
      // url: '/api/v1/atividades/:slug'
      // url: '/api/v1/atividades/:slug'
      url: `/api/v1/users/${userId}`,
      userId
    });
    
    if (res.data.status === 'success') {
      showAlert('success', 'Usuário deletado!');
      window.setTimeout(() => {
        location.assign('/usuarios/aprovado');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const approveUser = async userId => {
  try {
    // const slug = req.params.slug;
    const res = await axios ({
      method: 'PATCH',
      // url: '/api/v1/atividades/:slug'
      // url: '/api/v1/atividades/:slug'
      url: `/api/v1/users/${userId}/approval`,
      userId
    });
    console.log(res.data.status)
    if (res.data.status === 'success') {
      showAlert('success', 'Usuário aprovado!');
      window.setTimeout(() => {
        location.assign('/usuarios/aprovado');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const updateSettings = async (data, type) => {
  try {
      const url = type === 'password' ? '/api/v1/users/updateMyPassword' : '/api/v1/users/updateMe';
      
      const res = await axios({
          method: 'PATCH',
          url,
          data
      });

      if(res.data.status === 'success') {
          // showAlert('success', `${type.toUpperCase()} atualizado!`);
          showAlert('success', `Dados atualizados!`);
          window.setTimeout(() => {
            location.reload();
          }, 1500);          
      }
  } catch (err) {
      showAlert('error', err.response.data.message);
  }
};

export const createNewsletter = async (name, email) => {
  try {
    const res = await axios ({
      method: 'POST',
      url: '/api/v1/newsletters',
      data: {
        name,
        email
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Registro de Newsletter enviado!');
      window.setTimeout(() => {
        location.assign("/");
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const updateReservaEmprestado = async (reservaId, userId) => {
    try {
      // const slug = req.params.slug;
      const res = await axios ({
        method: 'PATCH',
        // url: '/api/v1/atividades/:slug'
        // url: '/api/v1/atividades/:slug'
        url: `/api/v1/reservas/${reservaId}`,
        reservaId,
        userId
      });
  
      if (res.data.status === 'success') {
        showAlert('success', 'Status da reserva alterado!');
        window.setTimeout(() => {
          location.assign(`/user-reservations/${userId}/reservado`);
        }, 1500);
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
    }
};

export const updateReservaDevolvido = async (reservaId, userId) => {
    try {
      // const slug = req.params.slug;
      const res = await axios ({
        method: 'PATCH',
        // url: '/api/v1/atividades/:slug'
        // url: '/api/v1/atividades/:slug'
        url: `/api/v1/reservas/${reservaId}/devolvido`,
        reservaId,
        userId
      });
  
      if (res.data.status === 'success') {
        showAlert('success', 'Status da reserva alterado!');
        window.setTimeout(() => {
          location.assign(`/user-reservations/${userId}/emprestado`);
        }, 1500);
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
    }
};


