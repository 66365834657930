import axios from 'axios';
import { showAlert } from './alerts';

export const createCalendarEvent = async (data) => {
    try {
      const res = await axios ({
        method: 'POST',
        url: '/api/v1/atividades/calendar-event',
        data
      });
  
      if (res.data.status === 'success') {
        showAlert('success', 'Atividade criada com sucesso!');
        window.setTimeout(() => {
          location.assign('/atividades');
        }, 1500);
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
    }
  };