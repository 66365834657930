import axios from 'axios';
import { showAlert } from './alerts';

// CREATES
  
  export const createBiblioteca = async (data) => {
    try {
      const res = await axios ({
        method: 'POST',
        url: '/api/v1/bibliotecas',
        data
      });
  
      if (res.data.status === 'success') {
        showAlert('success', 'Livro adicionado com sucesso!');
        window.setTimeout(() => {
          location.assign('/livros');
        }, 1500);
      }
    } catch (err) {
      showAlert('error', 'Já existe um livro com este título');
    }
};

export const createAtividade = async (data) => {
    try {
      const res = await axios ({
        method: 'POST',
        url: '/api/v1/atividades',
        data
      });
  
      if (res.data.status === 'success') {
        showAlert('success', 'Atividade criada com sucesso!');
        window.setTimeout(() => {
          location.assign('/atividades');
        }, 1500);
      }
    } catch (err) {
      showAlert('error', 'Já existe uma atividade com esse nome');
    }
};

export const createReserva = async (data, bibliotecaId) => {
    try {
      // 1) Get checkout session from API
      const res = await axios({
        method: 'POST',
        // url: '/api/v1/atividades/:slug'
        // url: '/api/v1/atividades/:slug'
        url: `/api/v1/reservas/create-reserva/${bibliotecaId}`,
        bibliotecaId,
        data
      });
  
      if (res.data.status === 'success') {
        showAlert('success', 'Reserva criada com sucesso!');
        window.setTimeout(() => {
          location.assign('/minhas-reservas/reservado');
        }, 1500);
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
    }
};

// UPDATES

export const updateBiblioteca = async(data, bibliotecaId) => {
    try {
        const res = await axios({
            method: 'PATCH',
            url: `/api/v1/bibliotecas/${bibliotecaId}`,
            bibliotecaId,
            data
        });
        // console.log('Frontend data', data);
        if(res.data.status === 'success') {
            showAlert('success', 'Alterações salvas!');
            window.setTimeout(() => {
              location.assign('/livros');
            }, 1500);
        }

    } catch(err) {
        // showAlert('error', err.response.data.message);
        showAlert('error', err.response.data.message);
    }
};

export const updateAtividade = async (data, atividadeId) => {
    try {      
        const res = await axios({
            method: 'PATCH',
            url: `/api/v1/atividades/${atividadeId}`,
            data,
            atividadeId
        });
  
        if(res.data.status === 'success') {
            showAlert('success', 'Atividade atualizada!'); 
            window.setTimeout(() => {
              location.assign('/atividades');
            }, 1500);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
  };

// DELETES

export const deleteBiblioteca = async bibliotecaId => {
    try {
      // const slug = req.params.slug;
      const res = await axios ({
        method: 'DELETE',
        // url: '/api/v1/atividades/:slug'
        // url: '/api/v1/atividades/:slug'
        url: `/api/v1/bibliotecas/${bibliotecaId}`,
        bibliotecaId
      });
  
      if (res.data.status === 'success') {
        showAlert('success', 'Livro deletado!');
        window.setTimeout(() => {
          location.assign('/livros');
        }, 1500);
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
    }
};

export const deleteAtividade = async atividadeId => {
  try {
    // const slug = req.params.slug;
    const res = await axios ({
      method: 'DELETE',
      // url: '/api/v1/atividades/:slug'
      // url: '/api/v1/atividades/:slug'
      url: `/api/v1/atividades/${atividadeId}`,
      atividadeId
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Atividade deletada!');
      window.setTimeout(() => {
        location.assign('/atividades');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
  

export const deleteReserva = async (reservaId, userId) => {
    try {
      // const slug = req.params.slug;
      const res = await axios ({
        method: 'DELETE',
        // url: '/api/v1/atividades/:slug'
        // url: '/api/v1/atividades/:slug'
        url: `/api/v1/reservas/${reservaId}`,
        reservaId,
        userId
      });
  
      if (res.data.status === 'success') {
        showAlert('success', 'Reserva deletada!');
        window.setTimeout(() => {
          // location.assign('/minhas-reservas/reservado');
          location.reload();
        }, 1500);
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
    }
};

export const adminDeleteReservation = async (reservaId, userId) => {
    try {
      // const slug = req.params.slug;
      const res = await axios ({
        method: 'DELETE',
        // url: '/api/v1/atividades/:slug'
        // url: '/api/v1/atividades/:slug'
        url: `/api/v1/reservas/${reservaId}`,
        reservaId,
        userId
      });
  
      if (res.data.status === 'success') {
        showAlert('success', 'Reserva deletada!');
        window.setTimeout(() => {
          location.assign(`/user-reservations/${userId}/reservado`);
        }, 1500);
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
    }
};

export const createReservationAdmin = async (userId, bookId) => {
  try {
    // 1) Get checkout session from API
    const res = await axios({
      method: 'POST',
      url: `/api/v1/reservas/create-reservation/${userId}/${bookId}`,
      userId,
      bookId
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Reserva criada com sucesso!');
      window.setTimeout(() => {
        location.assign(`/user-reservations/${userId}/reservado`);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};